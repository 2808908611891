/**
 * Converts a hex color to HSB (HSV) values
 * @param {string} hex - The hex color string (e.g., "#FF0000")
 * @returns {Object} HSB values { h, s, b }
 */

const hexToHSB = (hex) => {
  // Remove # if present
  hex = hex.replace(/^#/, "");

  // Convert to RGB first
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h = 0;
  let s = max === 0 ? 0 : delta / max;
  let v = max;

  if (delta !== 0) {
    if (max === r) {
      h = ((g - b) / delta) % 6;
    } else if (max === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);
    if (h < 0) h += 360;
  }

  // Convert to percentages
  s = Math.round(s * 100);
  v = Math.round(v * 100);

  return { h, s, b: v };
};

/**
 * Converts HSB (HSV) values to hex color
 * @param {Object} hsb - The HSB values { h, s, b }
 * @returns {string} Hex color string
 */
const HSBToHex = ({ h, s, b }) => {
  s /= 100;
  b /= 100;

  const k = (n) => (n + h / 60) % 6;
  const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));

  const rgb = [
    Math.round(255 * f(5)),
    Math.round(255 * f(3)),
    Math.round(255 * f(1)),
  ];

  return (
    "#" +
    rgb
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

/**
 * Generates brand color palette from base color
 * @param {string} baseColor - Base hex color
 * @returns {Object} Brand color palette
 */
export const generateBrandColors = (baseColor) => {
  const baseHSB = hexToHSB(baseColor);

  return {
    "accent-60": HSBToHex({
      h: baseHSB.h, // Keep base hue
      s: 75, // Fixed saturation
      b: 55, // Fixed brightness
    }),
    "accent-50": baseColor, // Original brand color
    "accent-40": HSBToHex({
      h: baseHSB.h,
      s: 56, // Fixed saturation
      b: 88, // Fixed brightness
    }),
    "accent-20": HSBToHex({
      h: baseHSB.h,
      s: 17, // Fixed saturation
      b: 93, // Fixed brightness
    }),
    "accent-10": HSBToHex({
      h: baseHSB.h,
      s: 1, // Fixed saturation
      b: 98, // Fixed brightness
    }),
    "accent-5": HSBToHex({
      h: baseHSB.h,
      s: 1, // Fixed saturation
      b: 100, // Fixed brightness
    }),
  };
};

/**
 * Checks if a color is accessible against white or black background
 * @param {string} color - Hex color to check
 * @returns {Object} Accessibility information
 */
export const checkColorAccessibility = (color) => {
  const hsb = hexToHSB(color);
  return {
    withWhite: hsb.b < 50, // Dark colors are accessible on white
    withBlack: hsb.b > 50, // Light colors are accessible on black
  };
};

// Example usage:
// const brandColors = generateBrandColors('#2A6EAA');
// console.log(brandColors);
//
// const accessibility = checkColorAccessibility('#2A6EAA');
// console.log(accessibility);
