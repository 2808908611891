import React, { useEffect, useState } from "react";
import {
  ShoppingBagIcon,
  XMarkIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
  SheetDescription,
} from "../shadcn_components/ui/sheet.tsx";
import { Button } from "../shadcn_components/ui/button.tsx";
import { ScrollArea } from "../shadcn_components/ui/scroll-area.tsx";

const freqMap = {
  MONTHLY: ' / month',
  YEARLY: ' / year',
  ONE_TIME: '',
  WEEKLY: ' / week',
  DAILY: ' / day',
  QUARTERLY: ' / quarter',
  SEMI_ANNUAL: ' / semi-annual',
  ANNUAL: ' / year',
}
const AddToCartSheet = () => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [cart, setCart] = useState({
    data: null,
    cartItems: [],
    totalAmount: 0,
    currency: "USD",
  });
  const [removingItems, setRemovingItems] = useState(new Set());
  const [isMobile, setIsMobile] = useState(false);

  // Handle sheet state changes
  useEffect(() => {
    if (isSheetOpen) {
      logEvent("Sheet Opened", "Requesting fresh cart data");
    }
  }, [isSheetOpen]);

  // Single useEffect for message handling
  useEffect(() => {
    logEvent("Initializing", "Setting up message listener");

    const handleMessage = (event) => {
      const data = event.data;

      // Handle object messages
      if (typeof data === "object" && data !== null) {
        switch (data.action) {
          case "CART_SHEET_OPENED":
            const cartData =
              typeof data.data === "string" ? JSON.parse(data.data) : data.data;
            setIsSheetOpen(true);
            setCart({
              data: cartData.data || null,
              cartItems: Array.isArray(cartData.cartItems)
                ? cartData.cartItems
                : [],
              totalAmount: parseFloat(cartData.totalAmount || 0),
              currency: cartData.currency || "USD",
            });
            break;

          case "UPDATED_CART":
            if (data.data) {
              try {
                const cartData =
                  typeof data.data === "string"
                    ? JSON.parse(data.data)
                    : data.data;
                setCart({
                  data: cartData.data || null,
                  cartItems: Array.isArray(cartData.cartItems)
                    ? cartData.cartItems
                    : [],
                  totalAmount: parseFloat(cartData.totalAmount || 0),
                  currency: cartData.currency || "USD",
                });
              } catch (error) {
                console.error("Cart Update Error:", error);
              }
            }
            break;
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    console.log("value of cart", cart);
  }, [cart]);

  useEffect(() => {
    //send message to parent to close the sheet when the isSheetOpen is false
    if (!isSheetOpen) {
      // wait for 100ms before sending the message
      setTimeout(() => {
        window.top.postMessage(
          {
            action: "CART_SHEET_CLOSED",
            entity: "CHARITYSTACK",
            sender: "ADD_TO_CART_SHEET",
          },
          "*",
        );
      }, 200);
    }
  }, [isSheetOpen]);

  const logEvent = (type, data) => {
    console.log(`[AddToCartSheet] ${type}:`, data);
  };

  // Handle sheet visibility changes
  useEffect(() => {
    logEvent("Sheet State Changed", { isOpen: isSheetOpen });
    const iframe = window.frameElement;
    if (iframe) {
      iframe.style.visibility = isSheetOpen ? "visible" : "hidden";
    }
  }, [isSheetOpen]);

  const handleRemoveItem = async (index, item) => {
    // Prevent double-clicks during animation
    if (removingItems.has(index)) return;

    // Set removing state for animation
    setRemovingItems(prev => new Set([...prev, index]));

    try {
      // Calculate new cart state
      const newCartItems = cart.cartItems.filter((_, i) => i !== index);
      const newTotalAmount = newCartItems.reduce(
        (total, item) => total + (item.amount * (item.quantity || 1)),
        0
      );

      // Update local state first
      const newCart = {
        ...cart,
        cartItems: newCartItems,
        totalAmount: newTotalAmount
      };

      setCart(newCart);

      // Notify parent about item removal
      window.top.postMessage({
        action: "REMOVE_CART_ITEM",
        entity: "CHARITYSTACK",
        sender: "ADD_TO_CART_SHEET",
        data: {
          itemIndex: index,
          item: item,
          updatedCart: newCart
        }
      }, "*");

      // Remove item from animation set after animation completes
      setTimeout(() => {
        setRemovingItems(prev => {
          const next = new Set(prev);
          next.delete(index);
          return next;
        });
      }, 300);

    } catch (error) {
      console.error("Error removing item:", error);
      // Reset removing state if there's an error
      setRemovingItems(prev => {
        const next = new Set(prev);
        next.delete(index);
        return next;
      });
    }
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // 640px is Tailwind's 'sm' breakpoint
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <Sheet
      open={isSheetOpen}
      onOpenChange={(open) => {
        setIsSheetOpen(open);
        if (!open) {
          window.top.postMessage(
            {
              action: "CART_SHEET_CLOSED",
              entity: "CHARITYSTACK",
              sender: "ADD_TO_CART_SHEET",
            },
            "*",
          );
        }
      }}
      modal={true}
    >
      <SheetContent
        side={isMobile ? "top" : "right"}
        className="flex h-full w-full flex-col overflow-hidden bg-white sm:w-[400px]"
        onPointerDownOutside={(e) => {
          e.preventDefault();
          setIsSheetOpen(false);
        }}
        onEscapeKeyDown={() => setIsSheetOpen(false)}
      >
        <div className="flex items-center justify-between">
          <SheetTitle className="text-2xl text-black font-normal open_sans">Your Cart</SheetTitle>
          <button
            onClick={() => setIsSheetOpen(false)}
            className="flex items-center p-1 rounded-full bg-black/25 transition-opacity hover:opacity-100"
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="fill-white">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </button>
        </div>

        <ScrollArea className="flex-1">
          <div className="flex-1 py-4 relative">
            {cart.cartItems?.length === 0 ? (
              <div className="py-8 text-center text-gray-500">
                Your cart is empty
              </div>
            ) : (
              cart.cartItems?.map((item, index) => (
                <div
                  key={index}
                  className={`
                    py-2
                    ${removingItems.has(index) ?
                      'transition-all duration-300 ease-out opacity-0 transform -translate-y-[20px]' :
                      ''
                    }
                  `}
                  style={{
                  //  height: removingItems.has(index) ? '0' : 'auto',
                  //  marginBottom: removingItems.has(index) ? '0' : '1rem',
                  //  padding: removingItems.has(index) ? '0' : '1rem',
                  //  overflow: 'hidden',
                  }}
                >
                  <div className="flex open_sans flex-col items-start gap-3 self-stretch rounded-md border border-[rgba(30,30,30,0.05)] bg-[#FCFEFF] p-4 custom-shadow">
                    <div className="flex items-start justify-between w-full">
                      <div className="flex items-start gap-4">
                        <div className="flex h-6 w-6 items-center justify-center rounded-md border border-blue-600 bg-blue-100 flex-shrink-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                            <path d="M2.72422 8.22746V4.86859H3.29952C3.40324 4.86859 3.50947 4.87698 3.61822 4.89374C3.72697 4.91058 3.82956 4.93722 3.92599 4.97366L6.30863 5.82305C6.49756 5.89426 6.65594 6.00443 6.78379 6.15356C6.91163 6.30276 6.97555 6.48049 6.97555 6.68677C6.97555 6.72321 6.96152 6.75393 6.93346 6.77893C6.90547 6.80386 6.86681 6.81632 6.81746 6.81632H6.13371C5.99748 6.81632 5.86027 6.80918 5.72207 6.79489C5.58381 6.78061 5.44795 6.75047 5.31449 6.70448L4.71492 6.50342L4.54841 6.945L5.08161 7.13087C5.25261 7.18692 5.42404 7.22755 5.59591 7.25277C5.7677 7.27799 5.94416 7.2906 6.12529 7.2906H8.60874C8.7887 7.2906 8.93225 7.35984 9.03939 7.49833C9.14646 7.63681 9.2 7.79913 9.2 7.98529L6.01432 9.14584L2.72422 8.22746ZM0.799999 8.96927V4.86859H2.1557V8.96927H0.799999ZM6.85868 5.1065L5.91504 4.26301C5.24674 3.64573 4.8467 3.19496 4.71492 2.9107C4.58314 2.62644 4.51725 2.38981 4.51725 2.20081C4.51725 1.86108 4.63263 1.5777 4.86339 1.35065C5.09408 1.12354 5.38657 1.00998 5.74088 1.00998C5.96238 1.00998 6.1676 1.06508 6.35652 1.17529C6.54552 1.28542 6.7129 1.42489 6.85868 1.5937C6.99716 1.4176 7.16269 1.27631 7.35526 1.16982C7.54783 1.06326 7.75486 1.00998 7.97637 1.00998C8.3161 1.00998 8.60495 1.129 8.84292 1.36705C9.08097 1.60503 9.2 1.89388 9.2 2.23361C9.2 2.42261 9.13706 2.65741 9.01118 2.93803C8.88531 3.21864 8.48822 3.6603 7.81992 4.26301L6.85868 5.1065Z" fill="#2A6EA5" />
                          </svg>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-[#1E1E1E] font-sans text-base font-normal leading-[1.5] tracking-wider">
                          Gazan Family Relief Campaign
                          </div>
                          <div className="font-sans text-sm font-normal leading-5 text-gray-500">
                          Zakaat Fund
                          </div>
                        </div>
                      </div>
                      
                      <div className="flex items-center gap-4">
                      <div className="text-[#1E1E1E] font-sans text-base font-normal leading-[1.5] tracking-wider">
                      ${item.amount.toFixed(2)}
                          <br></br>
                          <span className="text-sm text-gray-500">
                          {freqMap[item.frequency]}
                          </span>
                          </div>
                         <button
                          className="rounded-full p-1.5 text-red-400 hover:bg-red-50 hover:text-red-600 transition-colors duration-200"
                          onClick={() => handleRemoveItem(index, item)}
                          data-tooltip-id="remove-tooltip"
                          data-tooltip-content="Remove item"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button> 
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </ScrollArea>

        <div className="space-y-4 border-t pt-4">
          <div className="flex items-center justify-between text-base">
            <span className="text-gray-600">Subtotal</span>
            <span className="font-medium">
              ${cart.cartItems
                .reduce((total, item) => total + (item.amount * item.quantity), 0)
                .toFixed(2)}
            </span>
          </div>
          <div className="flex items-center justify-between text-base">
            <span className="font-medium text-gray-900">Total</span>
            <span className="font-medium">
              ${cart.cartItems
                .reduce((total, item) => total + (item.amount * item.quantity), 0)
                .toFixed(2)}
            </span>
          </div>
          <Button
            className="w-full bg-blue-700 py-6 text-white hover:bg-blue-800"
            onClick={() => {
              logEvent("Checkout Click", "User initiated checkout");
              window.top.postMessage(
                {
                  action: "PROCEED_TO_CHECKOUT",
                  entity: "CHARITYSTACK",
                  sender: "ADD_TO_CART_SHEET",
                  data: JSON.stringify(cart),
                },
                "*"
              );
            }}
          >
            Checkout
          </Button>
          <Button
            variant="outline"
            className="w-full border-gray-200 py-6 text-gray-700 hover:bg-gray-50"
            onClick={() => setIsSheetOpen(false)}
          >
            Add other items
          </Button>
        </div>
      </SheetContent>
      <Tooltip id="remove-tooltip" />
    </Sheet>
  );
};

export default AddToCartSheet;
