import React from "react";

export function BrandColorProvider({ brandColors, children }) {
  React.useEffect(() => {
    if (brandColors) {
      document.documentElement.style.setProperty(
        "--brand-60",
        brandColors["accent-60"],
      );
      document.documentElement.style.setProperty(
        "--brand-50",
        brandColors["accent-50"],
      );
      document.documentElement.style.setProperty(
        "--brand-40",
        brandColors["accent-40"],
      );
      document.documentElement.style.setProperty(
        "--brand-20",
        brandColors["accent-20"],
      );
      document.documentElement.style.setProperty(
        "--brand-10",
        brandColors["accent-10"],
      );
      document.documentElement.style.setProperty(
        "--brand-5",
        brandColors["accent-5"],
      );
    }
  }, [brandColors]);

  return children;
}
