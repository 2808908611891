import React, { useEffect, useState } from "react";
import { ShoppingBagIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from "../shadcn_components/ui/sheet.tsx";
import AddToCartSheet from "./AddToCartSheet";
import { generateBrandColors, checkColorAccessibility } from "./Util/HSB";
import { BrandColorProvider } from "./Util/BrandColorProvider";

const AddToCart = () => {
  const [data, setData] = useState({});
  const [cart, setCart] = useState({
    data: null,
    cartItems: [],
  });
  const [sourceWebsite, setSourceWebsite] = useState("");
  const [elementID, setElementID] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [color, setColor] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(
    "Your donation has been added to your cart.",
  );
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [brandColors, setBrandColors] = useState({});

  useEffect(() => {
    fetchElementsConfig();

    // Combine all event listeners in one useEffect

    window.addEventListener("resize", (event) => {
      if (window.innerWidth == windowWidth) return;
      if (window.innerWidth !== 48 && window.innerWidth !== 64) return;
      setWindowWidth(window.innerWidth);
    });
    window.addEventListener("message", updateCart);
    window.addEventListener("message", updateMessage);
    window.addEventListener("message", handleOpenSheet);

    // set the elementID and other initial setup...
    const urlParams = new URLSearchParams(window.location.search);
    let elementID = urlParams.get("elementID");
    setElementID(elementID);
    let sourceWebsiteParam = urlParams.get("sourceWebsite");
    console.log("sourceWebsite onload aisha", sourceWebsiteParam);
    // setSourceWebsite(sourceWebsiteParam);
    // setColor(urlParams.get("color"));
    setBrandColors(generateBrandColors(urlParams.get("color")));
    // send message to grab current cart
    window.top.postMessage(
      {
        action: "GET_CART",
        entity: "CHARITYSTACK",
        sender: "ADD_TO_CART",
        receiver: "HOSTED_SCRIPT",
        id: sourceWebsiteParam,
      },
      "*",
    );
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
      window.removeEventListener("message", updateCart);
      window.removeEventListener("message", updateMessage);
      window.removeEventListener("message", handleOpenSheet);
    };
  }, []);

  const handleOpenSheet = (event) => {
    if (
      event.data.entity === "CHARITYSTACK" &&
      (event.data.action === "CART_SHEET_OPENED" ||
        event.data.action === "UPDATE_CART_NOTIFICATION")
    ) {
      setIsSheetOpen(true);
    }
  };

  // Add this new function to handle item removal
  const handleRemoveItem = (index) => {
    const newCart = {
      ...cart,
      cartItems: cart.cartItems.filter((_, i) => i !== index),
    };
    setCart(newCart);
    window.top.postMessage(
      {
        action: "UPDATE_CART",
        entity: "CHARITYSTACK",
        sender: "ADD_TO_CART",
        receiver: "HOSTED_SCRIPT",
        data: JSON.stringify(newCart),
      },
      "*",
    );
  };

  const updateMessage = (event) => {
    if (
      !(
        event.data.entity == "CHARITYSTACK" &&
        event.data.action == "UPDATE_CART_NOTIFICATION"
      )
    )
      return;
    //console.log("event data here", event.data);
    //setNotificationMessage(event.data.message);
  };

  // update cart to be the new event
  const updateCart = (event) => {
    if (
      !(
        event.data.entity == "CHARITYSTACK" &&
        event.data.action == "UPDATED_CART"
      )
    )
      return;

    const newCart = JSON.parse(event.data.data);
    if (newCart == null) {
      setCart({
        data: null,
        cartItems: [],
      });
    } else {
      setCart(newCart);
    }
    // only send update cart notification if not loading
    // otherwise, this is just the response from getting the initial cart
    if (!isLoading) {
      setIsSheetOpen(true); // Also open sheet when cart is updated
      console.log("sourceWebsite frontend onClick", sourceWebsite);
      window.top.postMessage(
        {
          action: "CART_SHEET_OPENED",
          entity: "CHARITYSTACK",
          sender: "ADD_TO_CART",
          receiver: "HOSTED_SCRIPT",
          data: JSON.stringify(cart),
        },
        "*",
      );

      window.top.postMessage(
        {
          action: "UPDATED_CART",
          entity: "CHARITYSTACK",
          sender: "ADD_TO_CART",
          data: JSON.stringify(cart),
        },
        "*",
      );
    }
    setIsLoading(false);
  };

  const fetchElementsConfig = async () => {
    // Grab elements config using elementID in query params
    const urlParams = new URLSearchParams(window.location.search);
    // if elementID is not in query params, return a hardcoded one
    let elementID = urlParams.get("elementID");

    // if (elementID == null) {
    //   elementID =
    //     process.env.REACT_APP_ENVIRONMENT == "development"
    //       ? "d19c007c-6dae-48e9-9809-54905bc6ddaa"
    //       : "318bfedb-b2ca-497a-8b7f-9d0cccf6fb0c";
    // }

    const response = await fetch(
      `${process.env.REACT_APP_ELEMENTS_BACKEND_URL}/elements-config?elementID=${elementID}`,
    );
    const data = await response.json();
    setData(data);
    //console.log("data", data);
    setSourceWebsite(data.sourceWebsite);
    //console.log("windowWidth", windowWidth);
  };

  useEffect(() => {
    console.log("sourceWebsite frontend useEffect", sourceWebsite);
  }, [sourceWebsite]);

  const handleDonate = () => {
    // updated configData to include user inputs
    // data.frequency = frequency;
    // data.amount = amount;
    // data.fund = selectedFund;
    // data.customInputsValues = customInputsValues;
    // console.log("Naumaan: ", cart.cartItems)
    const cartQuantity = cart.cartItems.reduce((a, b) => a + b.quantity, 0);
    if (cartQuantity == 0) return;

    window.top.postMessage(
      {
        action: "DONATE",
        entity: "CHARITYSTACK",
        sender: "ADD_TO_CART",
        receiver: "HOSTED_SCRIPT",
        hostedPage: null,
        donationData: JSON.stringify(cart),
      },
      "*",
    );
    // }, window.location.ancestorOrigins[0]);
  };

  return (
    <BrandColorProvider brandColors={brandColors}>
      <div
        className="items-cente flex h-[40px] flex-row px-4 py-2"
        style={{ backgroundColor: brandColors["accent-10"] }}
      >
        {brandColors != {} && !isLoading ? (
          <div
            onClick={() => {
              window.top.postMessage(
                {
                  action: "CART_SHEET_OPENED",
                  entity: "CHARITYSTACK",
                  sender: "ADD_TO_CART",
                  receiver: "HOSTED_SCRIPT",
                  data: JSON.stringify(cart),
                },
                "*",
              );
            }}
            className="ml-auto flex cursor-pointer flex-row items-center gap-1"
          >
            <ShoppingBagIcon
              className={`h-6 w-6`}
              style={{ strokeWidth: 1.0, color: brandColors["accent-50"] }}
            />
            <div
              className="open_sans text-xl"
              style={{ color: brandColors["accent-50"] }}
            >
              Cart ({cart.cartItems.reduce((a, b) => a + b.quantity, 0)})
            </div>
          </div>
        ) : (
          <>
            {/* <div className="ml-auto flex h-6 w-24 animate-pulse rounded-md bg-gray-200"></div> */}
          </>
        )}
      </div>
    </BrandColorProvider>
  );
};
export default AddToCart;
